import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import {
  i18nDefaultConfig,
  I18N_SUPPORTED_LANGUAGES,
  composeI18nFormatters,
} from '@pm/core/src/localization';
import LanguageDetector from 'i18next-browser-languagedetector';

if (import.meta.env.MODE === 'test') {
  throw new Error(
    'i18n should not be imported in test mode. Import directly from "i18next"',
  );
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: import.meta.env.VITE_DEBUG_I18N === 'true',
    ...i18nDefaultConfig,
    supportedLngs: [I18N_SUPPORTED_LANGUAGES.en, I18N_SUPPORTED_LANGUAGES.es],
    nonExplicitSupportedLngs: true,
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        // 'htmlTag', htmlTag is disabled because for some reason it's overriding the navigator
      ],
    },
  });

export default composeI18nFormatters(i18n);
