import { ApolloProvider } from '@apollo/client';
import {
  API_HOST,
  BRAND_NAME,
  ENVIRONMENT,
  LAUNCH_DARKLY_SDK_KEY,
  sentryConfigs,
  SharedAppProviders,
} from '@pm/core';
import { Toaster } from '@pm/ui';
import * as Sentry from '@sentry/react';
import { ComponentProps, StrictMode, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { apolloClient } from './api/client';
import App from './apps';
import SEO from './utilities/seo';
import '@material-symbols/font-500/rounded.css';

// translations
import './localization/i18n';

// css files
import '@pm/ui/themes/freddie.css';
import '@pm/ui/themes/global.css';
import 'tailwindcss/tailwind.css';

console.log(
  'Environment variables:',
  import.meta.env.VITE_ENVIRONMENT,
  import.meta.env.VITE_GIT_PULL_REQUEST_ID,
);
const isProdEnv = import.meta.env.VITE_ENVIRONMENT === 'production';

// initialize our Sentry crash reporting tool
if (import.meta.env.PROD) {
  const sentryEnvironment = isProdEnv ? 'prod' : 'dev';

  Sentry.init({
    dsn: 'https://2659d8349ea649a98bf09338af64d8fa@o371410.ingest.sentry.io/5382518',
    environment: sentryEnvironment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    replaysSessionSampleRate: sentryEnvironment === 'prod' ? 0.5 : 0.0,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: sentryEnvironment === 'prod' ? 0.5 : 1.0,
    tracePropagationTargets: [
      'https://api.purposemed.com/graphql',
      'https://api-staging.gofreddie.com/graphql',
    ],
    ...sentryConfigs,
  });
}

const ENV_OPTIONS: Omit<
  ComponentProps<typeof SharedAppProviders>,
  'children'
> = {
  brand: BRAND_NAME.Freddie,
  apiHost: API_HOST,
  environment: ENVIRONMENT,
  launchDarklySDKKey: LAUNCH_DARKLY_SDK_KEY,
  formattingContext: 'patient',
  enableGoogleAnalyticsTracking: true,
};

createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <Suspense fallback={null}>
          <ApolloProvider client={apolloClient}>
            <SharedAppProviders {...ENV_OPTIONS}>
              <SEO />
              <Toaster>
                <App />
              </Toaster>
            </SharedAppProviders>
          </ApolloProvider>
        </Suspense>
      </HelmetProvider>
    </BrowserRouter>
  </StrictMode>,
);
